<template>
	<div class="row-space-tbf detailed" v-if="loaded">
		<div class="space-left"></div>
        <users v-if="activeTab == 'users'"/>
		<div class="space-right"></div>
	</div>
</template>

<script>
	import Users from './Users'

    export default {
    	components: {
            Users
        },
        watch:{
			$route (to, from){
				this.activeTab = this.$route.query.type ? this.$route.query.type : 'users'
            }
        },
        data() {
            return {
            	activeTab: 'users',
                loaded: false
            }
        },
        async mounted(){
            if(this.$route.query.type){
				this.activeTab = this.$route.query.type
                this.$root.$emit('change_'+this.$route.query.type);
			}

            setTimeout(() => {
                var title = this.$t('navbar.reports');
                this.$root.$emit("navbar_title", title);
                setTimeout(() => {
                    this.loaded = true
                }, 0)
            }, 0)
        },
        methods: {
        	changeTab(value){
        		this.activeTab = value
        	}
        }
    }
</script>

<style lang="scss" scoped>
</style>